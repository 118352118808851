import { useState } from "react";
import styled from "styled-components";
import { ReactComponent as GrafanaIcon } from "../../assets/images/grafana_icon.svg";
import Dropdown from "../common/_dropdown";
import { remToPx } from "../../utils";
import GrafanaCredentialsDropdown from "./GrafanaCredentialsDropdown";

const StyledGrafanaIcon = styled(GrafanaIcon)`
  height: 1.4rem;
  width: 1.4rem;

  cursor: pointer;
  path {
    fill: ${({ $isGrafanaDashOpen }) =>
      $isGrafanaDashOpen ? "#47CCD6" : "#F6F6F6"};
  }
`;

const dropdownOffset = remToPx(1.2);

const GrafanaDashBoardButton = () => {
  const [isGrafanaDashOpen, setGrafanaDashOpen] = useState(false);

  return (
    <Dropdown
      open={isGrafanaDashOpen}
      onOpenChange={setGrafanaDashOpen}
      offsetValue={dropdownOffset}
      placement="bottom-end"
      content={
        <GrafanaCredentialsDropdown
          $closeDropdown={() => setGrafanaDashOpen(false)}
        />
      }
    >
      <StyledGrafanaIcon
        onClick={() => setGrafanaDashOpen(!isGrafanaDashOpen)}
        $isGrafanaDashOpen={isGrafanaDashOpen}
      />
    </Dropdown>
  );
};

export default GrafanaDashBoardButton;
