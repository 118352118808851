import React, { useState, useContext } from "react";
import styled from "styled-components";
import DontShowPasswordIcon from "../../assets/images/dont_show_password_icon.svg";
import ShowPasswordIcon from "../../assets/images/show_password_icon.svg";
import { ReactComponent as CopyContentIcon } from "../../assets/images/copy_content_icon.svg";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import Tooltip from "../../components/common/_tooltip";
import PropTypes from "prop-types";

const StyledCopyIcon = styled(CopyContentIcon)`
  height: 1.527rem;
  width: 1.666rem;
  cursor: pointer;
`;

const StyleRow = styled.div`
  display: flex;
  align-items: center;
`;

const DropDownContainer = styled.div`
  position: absolute;
  box-shadow: 4px 4px 16px 4px #0000001c;
  border: 1px solid var(--white-1, #f6f6f6);
  border-radius: 12px;
  z-index: 1000;
  width: 14.166rem;
  padding: 1.12rem;
  top: 8.6rem;
  right: 16.35rem;
  background-color: #2f2f2f;
`;

const TitleAndSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 12.7rem;
`;

const Text = styled.div`
  font-family: "Articulat CF Medium";
  margin: 0;
  color: #777777;
  font-size: 0.84rem;
`;

const SubText = styled.div`
  font-family: "Articulat CF Medium";
  color: #f6f6f6;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 10rem;
`;

const KafkaBrokerDropDown = ({ username, password, broker }) => {
  const [showPassword, setShowPassword] = useState(false);
  const { showMessage } = useContext(SnackbarContext);

  // Functions to copy username and password
  const handleUsernameCopy = () => {
    navigator.clipboard.writeText(username);
    showMessage("Kafka Broker username copied to clipboard", "success");
  };

  const handlePasswordCopy = () => {
    navigator.clipboard.writeText(password);
    showMessage("Kafka Broker Password copied to clipboard", "success");
  };

  const handleBrokerAddressCopy = () => {
    navigator.clipboard.writeText(broker);
    showMessage("Kafka Broker Address copied to clipboard", "success");
  };
  return (
    <DropDownContainer>
      <StyleRow>
        <TitleAndSubtitleContainer>
          <Text>Username</Text>
          <SubText>{username}</SubText>
        </TitleAndSubtitleContainer>
        <StyledCopyIcon onClick={handleUsernameCopy} />
      </StyleRow>
      <StyleRow style={{ paddingTop: "1.112rem" }}>
        <TitleAndSubtitleContainer>
          <Text>
            Password
            <img
              src={showPassword ? ShowPasswordIcon : DontShowPasswordIcon}
              alt="Show Password"
              onClick={() => setShowPassword(!showPassword)}
              style={{
                cursor: "pointer",
                height: "1rem",
                width: "1rem",
                marginBottom: "0.2rem",
                marginLeft: "0.5rem",
              }}
            />
          </Text>
          <Tooltip
            title={showPassword ? password : "*".repeat(password.length)}
          >
            <SubText>
              {showPassword ? password : "*".repeat(password.length)}
            </SubText>
          </Tooltip>
        </TitleAndSubtitleContainer>
        <StyledCopyIcon onClick={handlePasswordCopy} />
      </StyleRow>
      <StyleRow style={{ paddingTop: "1.112rem" }}>
        <TitleAndSubtitleContainer>
          <Text>Broker Address</Text>
          <Tooltip title={broker}>
            <SubText>{broker}</SubText>
          </Tooltip>
        </TitleAndSubtitleContainer>
        <StyledCopyIcon onClick={handleBrokerAddressCopy} />
      </StyleRow>
    </DropDownContainer>
  );
};

KafkaBrokerDropDown.propTypes = {
  username: PropTypes.string,
  password: PropTypes.string,
  broker: PropTypes.string,
};

export default KafkaBrokerDropDown;
