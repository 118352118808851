import axios from "axios";
import decode from "jwt-decode";
import { env } from "./env";
import { importJWK, compactDecrypt } from "jose";
import aes from 'aes-js'

export const decryptJwe = async (token) => {
  const JWKCONFIG = {
    kty: "oct",
    kid: env.REACT_APP_JWE_KID_KEY,
    alg: "A256GCM",
    k: env.REACT_APP_JWE_K_KEY,
  };

  try {
    const key = await importJWK(JWKCONFIG, JWKCONFIG.alg);
    const { plaintext } = await compactDecrypt(token, key);
    const data = JSON.parse(new TextDecoder().decode(plaintext));
    return data;
  } catch (err) {
    console.error("Error while decrypting JWE:", err);
    return null;
  }
};

const ENCRYPTION_KEY = env.REACT_APP_AES_ENCRYPTION_KEY;
const BLOCK_SIZE = env.REACT_APP_AES_BLOCK_SIZE;

const padData = (data) => {
  const padding = BLOCK_SIZE - (data.length % BLOCK_SIZE);
  const paddedData = new Uint8Array(data.length + padding);
  paddedData.set(data);
  paddedData.fill(padding, data.length);
  return paddedData;
};

const removePadding = (data) => {
  const padding = data[data.length - 1];
  return data.slice(0, data.length - padding);
};

export const encryptData = (data) => {
  try {
    data = typeof data ==='string' ? data : JSON.stringify(data)
    const keyBytes = aes.utils.utf8.toBytes(ENCRYPTION_KEY);
    const textBytes = aes.utils.utf8.toBytes(data);
    const paddedData = padData(textBytes);
    const aesCtr = new aes.ModeOfOperation.ctr(keyBytes);
    const encryptedBytes = aesCtr.encrypt(paddedData);
    const encrypted = aes.utils.hex.fromBytes(encryptedBytes);
    return { data: encrypted }
  } catch (error) {
    console.error("Encryption error:", error);
    throw new Error("Encryption failed");
  }
};

export const decryptData = (encryptedHex) => {
  try {
    const keyBytes = aes.utils.utf8.toBytes(ENCRYPTION_KEY);
    const encryptedBytes = aes.utils.hex.toBytes(encryptedHex);
    const aesCtr = new aes.ModeOfOperation.ctr(keyBytes);
    const decryptedBytes = aesCtr.decrypt(encryptedBytes);
    const unpaddedBytes = removePadding(decryptedBytes);
    const decryptedText = aes.utils.utf8.fromBytes(unpaddedBytes);
    try {
      return JSON.parse(decryptedText)
    } catch (error) {
      return decryptedText
    }
  } catch (error) {
    console.error("Decryption error:", error);
    throw new Error("Decryption failed");
  }
};

// const urlCutter = (url) =>{
//   let u_url;
//   if (url.indexOf('/') === 0) {
//     u_url = url.slice(url.indexOf('/', 1) + 1); 
//   } else {
//     u_url = url.slice(url.indexOf('/') + 1); 
//   }
//   return u_url
// }

const axiosAdapter = async (method, url, predata) => {
  let data;
  // Check if encryption is needed
  if (!url.includes('kafkaadmin') && predata) {
    data = encryptData(predata);
  } else {
    data = predata;
  }

  // if(url.includes('pipeline')) {
  //   let u_url = urlCutter(url)
  //   url = `http://localhost:5009/${u_url}`
  // }

  // if(url.includes('user-management')) {
  //   let u_url = urlCutter(url)
  //   url = `http://localhost:4300/${u_url}`
  // }

  // if(url.includes('version-control')) {
  //   let u_url = urlCutter(url)
  //   url = `http://localhost:6755/${u_url}`
  // }

  // if(url.includes('admin/')) {
  //   let u_url = urlCutter(url)
  //   url = `http://localhost:5579/${u_url}`
  // }

  let getTokenFromStorage = localStorage.getItem("token");
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("loginId");
    localStorage.removeItem("userName");
    localStorage.removeItem("selectedWorkSpaceName");
    localStorage.removeItem("selectedWorkSpaceId");
    localStorage.removeItem("selectedWorkSpaceIconLink");
    localStorage.removeItem("roles");
  };

  if (getTokenFromStorage) {
    try {
      // Decrypt JWE to obtain JWT
      const jwtToken = await decryptJwe(getTokenFromStorage);
      const { exp } = decode(jwtToken);

      // Check for token expiration
      if (Date.now() / 1000 > exp) {
        logout();
        window.location.reload();
        throw new Error("Token expired");
      }
    } catch (error) {
      console.error("Token validation failed:", error);
      logout();
      window.location.reload();
      throw new Error("Token validation failed");
    }
  }

  let config = {
    method: method,
    url: url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getTokenFromStorage}`,
    },
    ...(data && { data }),
  };

  try {
    let axiosResponse = await axios(config);

    // Check if decryption is needed
    if (!url.includes('kafkaadmin')) {
      let decryptedData = decryptData(axiosResponse.data);
      axiosResponse.data = decryptedData;
    }

    return axiosResponse;
  } catch (error) {
    if (error.response && error.response.data) {
      let decryptedErrorData = decryptData(error.response.data);
      error.response.data = JSON.parse(decryptedErrorData);
      throw error;
    } else {
      console.error("Unhandled error:", error);
      throw error;
    }
  }
};

export const remToPx = (rem) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

export const getAllWorkspaces = async () => {
  const allWorkspaces = await axiosAdapter(
    "GET",
    env.REACT_APP_URL + "user-management/getAllWorkspaces",
  );
  return allWorkspaces.data;
};

export default axiosAdapter;
