import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import SuccessIcon from "../../assets/images/Success.svg";
import ErrorIcon from "../../assets/images/sync_problem_24dp_FILL0_wght400_GRAD0_opsz24 1.svg";
import { io } from "socket.io-client";
import { env } from "../../env";
import {
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import ReactJson from "@microlink/react-json-view";
import InProgressIcon from "../../assets/images/reset_wrench_24dp_FILL0_wght400_GRAD0_opsz24 1.svg";
import BackIcon from "../../assets/images/left_arrow_icon.svg";
import PublishConnectorModal from "../ApplicationModal/publishConnectorModal";
import BuildConfigIcon from "../../assets/images/build_config_icon.svg";
import BuildLogsIcon from "../../assets/images/build_logs_icon.svg";
import BuildStatusIcon from "../../assets/images/build_status_icon.svg";
import ConfigureEnvIcon from "../../assets/images/configure_env_icon.svg";
import ChevronRight from "../../assets/images/chevron_right.svg";
import PlusIcon from "../../assets/images/plus_icon.svg";
import CustomRow from "../common/_customRow";
import Column from "../common/_customColumn";
import ToggleButton from "../common/_toggleButtonNew";
import GenericSelection from "../common/_dropDownComponent";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import GenericModal from "../common/_genericModal";
import axiosAdapter from "../../utils";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
  StyledDeleteButton,
} from "../common/_buttonNewOne";
import { getCurrentBranch } from "../../pages/ApplicationsPage/utils";
import ENV_Empty_State from "../../assets/images/ENV_Empty_State.svg";
import { ReactComponent as InfoIcon } from "../../assets/images/info_icon.svg";

const EmptyStateContainer = styled.div`
  display: flex;
  gap: 1.67rem;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 2rem;
  background-image: url(${ENV_Empty_State});
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 1;
  height: 20.44rem;
  width: 40.44rem;
  margin-left: 13rem;
  margin-top: 2rem;

  @media (max-width: 576px) {
    /* Styles for mobile devices */
    height: 40.44rem;
    width: 70.44rem;
    margin-left: 18rem;
    margin-top: 5rem;
  }

  @media (min-width: 577px) and (max-width: 768px) {
    /* Styles for tablets */
    height: 40.44rem;
    width: 70.44rem;
    margin-left: 18rem;
    margin-top: 5rem;
  }

  // @media (min-width: 769px) and (max-width: 992px) {
  //     /* Styles for laptops */
  // }

  // @media (min-width: 993px) and (max-width: 1200px) {
  //   /* Styles for desktops */
  // }

  // @media (min-width: 993px) and (max-width: 1200px) {
  //   /* Styles for desktops */
  // }

  // @media (min-width: 1201px) {
  //   /* Styles for large desktops */
  // }
`;

const InfoIconStyled = styled(InfoIcon)`
  flex: none;
  width: 1.444rem;
  height: 1.444rem;
  margin-right: 0.555rem;
`;

const INPUT_TYPE_MAPPER = {
  text: { name: "Text", value: "text" },
  number: { name: "Number", value: "number" },
  password: { name: "Password", value: "password" },
  topic: { name: "Topic", value: "topic" },
  port: { name: "Text", value: "text" },
};

const MainContainer = styled.div`
  background-color: #171717;
  height: 100vh;
  width: 100vw;
  padding: 0px 1.112rem;
`;

const TopNavigationSection = styled.div`
  width: 100%;
  padding: 0.84rem 0px;
  height: 5.55rem;
  display: flex;
  gap: 1.112rem;
  align-items: center;
  justify-content: flex-start;
  background-color: #171717;
`;

const PageContentSection = styled.div`
  width: 100%;
  height: calc(100vh - 15rem);
  background-color: #171717;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 1.112rem;
    overflow: auto;
  }
`;

const PageContentLeftSection = styled.div`
  height: 100%;
  background-color: #171717;
  width: 39%;
  @media (max-width: 768px) {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  gap: 1.112rem;
`;

const PageContentRightSection = styled.div`
  height: 100%;
  background-color: #2f2f2f;
  border-radius: 0.84rem;
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
  overflow: auto;
  padding: 1.39rem;
`;

const BottomButtonSection = styled.div`
  width: 100%;
  height: 5.55rem;
  background-color: #171717;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.112rem;
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  color: #f6f6f6;
  font-size: 1.67rem;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
  margin-left: 0.277rem;
`;

const ConfigSection = styled.div`
  width: 100%;
  height: ${(props) =>
    props.isSuccess && props.isConfigureStep ? "52%" : "65%"};
  overflow: auto;
  background-color: #2f2f2f;
  border-radius: 0.84rem;
  padding: 1.112rem;
`;

const BuildResultSection = styled.div`
  width: 100%;
  height: 34%;
  overflow: auto;
  background-color: #2f2f2f;
  border-radius: 0.84rem;
  padding: 1.112rem;
`;

const BuildFieldSection = styled.div`
  width: 100%;
  background-color: #2f2f2f;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  gap: 1rem 1.425rem;
  @media (max-width: 838px) {
    grid-template-columns: 2fr;
  }
`;

const ConfigFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.55rem;
`;

const Label = styled.div`
  color: #b0b0b0;
  font-family: "Articulat CF Medium";
  font-size: 0.972rem;
  font-weight: 500;
  letter-spacing: 0;
`;

const InputFieldContainer = styled.div`
  padding: 0.972rem 0.84rem;
  width: 100%;
  border-radius: 0.84rem;
  border: 0.069rem solid #b0b0b0;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InputField = styled.input`
  font-family: "Articulat CF Medium";
  font-size: 0.972rem;
  font-weight: 500;
  line-height: 1.112rem;
  color: #fafafa;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;

  &::placeholder {
    color: #777777;
  }
`;

const ModalDescription = styled.div`
  font-size: 1.25rem;
  font-family: "Articulat CF Medium";
  color: #ececec;
  opacity: 0.7;
  letter-spacing: 0px;
  line-height: 1.55rem;
`;

export default function BuildLogsAndConfiguration() {
  const location = useLocation();
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);
  const socket = useRef(null);
  const [logs, setLogs] = useState([]);
  const [buildStatus, setBuildStatus] = useState(
    location.state?.openUserConfigs ? "Success" : "In Progress",
  );
  const [errorResponse, setErrorResponse] = useState("");
  const [buildId, setBuildId] = useState(location.state?.buildId || null);
  const [isPublishModalOpen, setPublishModalOpen] = useState(false);
  const [isConfigureStep, setConfigureStep] = useState(
    location.state?.openUserConfigs ? true : false,
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [customTransfer, setCustomTransfer] = useState(false);

  let tabs = [];
  if (customTransfer) {
    tabs = [
      {
        name: "title",
        label: "Title",
        placeholder: "Title",
        type: { name: "Text", value: "text" },
        description: "Application Title",
        isRequired: true,
        direction: "input",
      },
      {
        name: "portNumber",
        label: "Port Number",
        placeholder: "Enter Port",
        type: { name: "Text", value: "text" },
        description: "Port number for the application deployment",
        isRequired: true,
        direction: "input",
      },
    ];
  } else {
    tabs = [
      {
        name: "title",
        label: "Title",
        placeholder: "Title",
        type: { name: "Text", value: "text" },
        description: "Application Title",
        isRequired: true,
        direction: "input",
      },
    ];
  }

  const updateTabsArray = (tabs) => {
    if (!Array.isArray(tabs)) {
      console.error("Invalid input: 'tabs' must be an array", tabs);
      return tabs; // Return the input unchanged
    }
    const labelExists = tabs.some((tab) => tab.name === "portNumber");

    if (!labelExists) {
      const newObject = {
        name: "portNumber",
        label: "Port Number",
        placeholder: "Enter Port",
        type: { name: "Text", value: "text" },
        description: "Port number for the application deployment",
        isRequired: true,
        direction: "input",
      };

      // Insert the new object at the second position
      const updatedTabs = [...tabs];
      updatedTabs.splice(1, 0, newObject);

      return updatedTabs;
    }

    return tabs; // Return the array unchanged if "portNumber" label exists
  };

  const [defaultTabs, setDefaultTabs] = useState(tabs);
  const [envConfigs, setEnvConfigs] = useState(
    location.state?.oldUserConfigs && location.state?.oldUserConfigs !== "[]"
      ? updateTabsArray(JSON.parse(location.state?.oldUserConfigs))
          .filter((config) => customTransfer || config.name !== "portNumber")
          .map((config) => ({
            ...config,
            type: INPUT_TYPE_MAPPER[config?.type || "text"],
            ...(config.type && config.type !== "topic"
              ? { direction: "input" }
              : {}),
          }))
      : defaultTabs,
  );
  const [selectedEnvIndex, setSelectedEnvIndex] = useState(0);
  const [configSaveLoading, setConfigSaveLoading] = useState(false);
  const [isConfigSaveErrorModalOpen, setConfigSaveErrorModalOpen] =
    useState(false);
  const [configSaveErrorMessage, setConfigSaveErrorMessage] = useState(
    "There was a problem in saving your ENVs due to an error. Please try again.",
  );
  const [currentBranch, setCurrentBranch] = useState(null);

  useEffect(() => {
    if (location.state?.openUserConfigs) {
      (async () => {
        const currentBranch = await getCurrentBranch({
          applicationId: location.state?.applicationId,
          repoName: location.state?.repoName,
        });
        if (currentBranch && currentBranch.status === "Success") {
          setCurrentBranch(currentBranch.branchName);
        } else {
          showMessage("Failed to obtain the current branch", "error");
        }
      })();
    }
  }, [
    location.state?.applicationId,
    location.state?.openUserConfigs,
    location.state?.repoName,
    showMessage,
  ]);

  useEffect(() => {
    socket.current = io(env.REACT_APP_URL, {
      auth: {
        token: localStorage.getItem("token"),
      },
      path: "/docker/sockets",
    });

    const buildDockerImageResponseListener = (data) => {
      if (data.code !== 200) {
        setBuildStatus("Failed");
        setErrorResponse(data.message);
        setLogs([{ status: "Build process failed" }]);
      } else {
        setBuildStatus("Success");
        setBuildId(data?.data?.buildId);
      }
    };

    const buildDockerImageLogListener = (data) => {
      if (
        JSON.stringify({
          stream: "\n",
        }) !== JSON.stringify(data)
      ) {
        setLogs((prevLogs) => [...prevLogs, data]);
      }
    };

    const socketDisconnectListner = (reason) => {
      if (reason === "io server disconnect") {
        socket.current.connect();
      }
    };

    if (socket.current && !location.state?.openUserConfigs) {
      socket.current.on(
        "buildDockerImageResponse",
        buildDockerImageResponseListener,
      );
      socket.current.on("buildDockerImageLog", buildDockerImageLogListener);
      socket.current.on("disconnect", socketDisconnectListner);

      socket.current.emit("/buildDockerImage", {
        imageName: location.state?.imageName,
        tag: location.state?.tagName,
        workspaceId: parseInt(localStorage.getItem("selectedWorkspaceId") || 0),
        inputType: "GIT",
        repoName: location.state?.repoName,
        branch: location.state?.branch,
        username: location.state?.username,
        applicationId: location.state?.applicationId,
      });
    }

    return () => {
      if (socket.current) {
        socket.current.off(
          "buildDockerImageResponse",
          buildDockerImageResponseListener,
        );
        socket.current.off("buildDockerImageLog", buildDockerImageLogListener);
        socket.current.off("disconnect", socketDisconnectListner);
        socket.current.disconnect();
      }
    };
  }, [location.state]);

  useEffect(() => {
    let configValues = envConfigs
      .filter((config) => customTransfer || config.name !== "portNumber")
      .map((config) => ({
        ...config,
        type: INPUT_TYPE_MAPPER[config?.type || "text"],
        ...(config.type && config.type !== "topic"
          ? { direction: "input" }
          : {}),
      }));

    if (customTransfer) {
      if (
        location.state?.oldUserConfigs &&
        location.state?.oldUserConfigs !== "[]"
      ) {
        const oldConfigs = updateTabsArray(
          JSON.parse(location.state.oldUserConfigs),
        );
        const portConfig = oldConfigs.find(
          (config) => config.name === "portNumber",
        );

        const portNumberExists = configValues.some(
          (config) => config.name === "portNumber",
        );

        if (portConfig && !portNumberExists) {
          configValues.splice(1, 0, {
            ...portConfig,
            type: INPUT_TYPE_MAPPER[portConfig.type],
            direction: "input",
          });
        }
      } else {
        const portNumberExists = configValues.some(
          (config) => config.name === "portNumber",
        );

        if (!portNumberExists) {
          configValues.splice(1, 0, {
            name: "portNumber",
            label: "Port Number",
            placeholder: "Enter Port",
            type: { name: "Text", value: "text" },
            description: "Port number for the application deployment",
            isRequired: true,
            direction: "input",
          });
        }
      }
    }
    setEnvConfigs(configValues);
    if (configValues.length - 1 <= 0) {
      setSelectedEnvIndex(0);
    } else {
      setSelectedEnvIndex(configValues.length - 1);
    }
  }, [customTransfer]);

  const handleModal = () => {
    setPublishModalOpen(true);
  };
  // Redirect to homepage when there is no indication that this page was opened through redirection
  if (!Boolean(location.state?.isRedirected))
    return <Redirect to={{ pathname: "/" }} />;

  const handleConfigSave = async () => {
    setConfigSaveLoading(true);
    let envNameMapper = new Map();
    try {
      for (let i = 0; i < envConfigs.length; i++) {
        if (
          envConfigs[i].name === "" ||
          envConfigs[i].label === "" ||
          envConfigs[i].placeholder === ""
        ) {
          let error = new Error(
            `Please fill all the fields marked '*' for each configuration variable or remove the configuration variable from the list`,
          );
          error.code = "INPUT_ERROR";
          throw error;
        } else if (!/^[a-zA-Z0-9-_]+$/.test(envConfigs[i].name)) {
          let error = new Error(
            `Please fill the Environment Variable Name properly for '${envConfigs[i].label}'`,
          );
          error.code = "INPUT_ERROR";
          throw error;
        }
        if (envNameMapper.has(envConfigs[i].name)) {
          let error = new Error(
            `All the Environment Variable Names must be unique`,
          );
          error.code = "INPUT_ERROR";
          throw error;
        } else envNameMapper.set(envConfigs[i].name, true);
      }
      let configs = envConfigs.map((config) => ({
        name: config.name,
        label: config.label,
        placeholder: config.placeholder,
        type: config?.type?.value || "text",
        description: config.description,
        isRequired: config.isRequired,
        ...(config?.type?.value === "topic"
          ? {
              direction: config.direction,
            }
          : {}),
        ...(config.name === "portNumber"
          ? {
              min: 8000,
              max: 10000,
              type: "port",
            }
          : {}),
      }));

      let stringifiedData = JSON.stringify({
        buildId,
        config: configs,
        applicationId: location.state?.applicationId,
        isIngressRequired: customTransfer,
      });
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/updateBuildUserConfigs",
        stringifiedData,
      );
      setConfigSaveLoading(false);
      handleModal();
    } catch (err) {
      console.log("Err in Config Save", err);
      setConfigSaveLoading(false);
      if (err.code === "INPUT_ERROR") {
        showMessage(err.message);
      } else {
        if (err.response?.data?.remarks) {
          setConfigSaveErrorMessage(err.response.data.remarks);
        }
        setConfigSaveErrorModalOpen(true);
      }
    }
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDelete = () => {
    let checkVal = customTransfer ? 1 : 0;

    if (selectedEnvIndex > checkVal) {
      if (envConfigs.length > 1) {
        setEnvConfigs((prev) =>
          prev.filter((_, idx) => idx !== selectedEnvIndex),
        );
        if (selectedEnvIndex === 0) {
          setSelectedEnvIndex(0);
        } else {
          setSelectedEnvIndex((prev) => prev - 1);
        }
      } else {
        setEnvConfigs([]);
        setSelectedEnvIndex(-1);
      }
    }
    toggleDeleteModal();
  };

  return (
    <>
      <MainContainer>
        <TopNavigationSection>
          <img
            src={BackIcon}
            style={{ height: "2.225rem", width: "2.225rem", cursor: "pointer" }}
            alt="Go Back"
            onClick={() =>
              buildStatus !== "In Progress" && !configSaveLoading
                ? history.push("/codeEditor", {
                    isRedirected: true,
                    applicationId: location.state?.applicationId,
                    gitUsername: location.state?.username,
                    repositoryName: location.state?.repoName,
                    imageName: location.state?.imageName,
                    tag: location.state?.tagName,
                    applicationName: location.state?.oldApplicationName,
                    applicationVersion: location.state?.oldApplicationVersion,
                    description: location.state?.oldDescription,
                    icon_file: location.state?.oldIconFile,
                    userConfigs: location.state?.oldUserConfigs,
                    authProvider: location.state?.authProvider,
                  })
                : null
            }
          />
          <Text
            style={{
              fontFamily: "Articulat CF Medium",
              fontWeight: "bold",
            }}
          >
            Configure Deployment
          </Text>
        </TopNavigationSection>
        <PageContentSection>
          <PageContentLeftSection>
            <ConfigSection
              isSuccess={buildStatus === "Success"}
              isConfigureStep={isConfigureStep}
            >
              <Text
                style={{
                  fontSize: "1.39rem",
                  marginBottom:
                    buildStatus === "Success" && isConfigureStep
                      ? "1.2rem"
                      : "2.225rem",
                  display: "flex",
                  gap: "0.84rem",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  fontFamily: "Articulat CF Medium",
                  fontWeight: "bold",
                }}
              >
                <img
                  src={BuildConfigIcon}
                  alt="Settings Icon"
                  style={{ height: "2.225rem", width: "2.225rem" }}
                />
                Add your Configurations
              </Text>
              <BuildFieldSection>
                <ConfigFieldContainer>
                  <Text
                    style={{
                      fontSize: "1.112rem",
                      color: "#777777",
                      fontWeight: "500",
                      letterSpacing: "0",
                    }}
                  >
                    Image Name
                  </Text>
                  <Text
                    style={{
                      fontSize: "1.25rem",
                      fontFamily: "Articulat CF Medium",
                      fontWeight: "bold",
                    }}
                  >
                    {location.state?.imageName || "NA"}
                  </Text>
                </ConfigFieldContainer>
                <ConfigFieldContainer>
                  <Text
                    style={{
                      fontSize: "1.112rem",
                      color: "#777777",
                      fontWeight: "500",
                      letterSpacing: "0",
                    }}
                  >
                    Tag Name
                  </Text>
                  <Text
                    style={{
                      fontSize: "1.25rem",
                      fontFamily: "Articulat CF Medium",
                      fontWeight: "bold",
                    }}
                  >
                    {location.state?.tagName || "NA"}
                  </Text>
                </ConfigFieldContainer>
                <ConfigFieldContainer>
                  <Text
                    style={{
                      fontSize: "1.112rem",
                      color: "#777777",
                      fontWeight: "500",
                      letterSpacing: "0",
                    }}
                  >
                    Branch Name
                  </Text>
                  <Text
                    style={{
                      fontSize: "1.25rem",
                      fontFamily: "Articulat CF Medium",
                      fontWeight: "bold",
                    }}
                  >
                    {(location.state?.branch || currentBranch) ?? "NA"}
                  </Text>
                </ConfigFieldContainer>
                <ConfigFieldContainer>
                  <Text
                    style={{
                      fontSize: "1.112rem",
                      color: "#777777",
                      fontWeight: "500",
                      letterSpacing: "0",
                    }}
                  >
                    Repository Name
                  </Text>
                  <Text
                    style={{
                      fontSize: "1.25rem",
                      fontFamily: "Articulat CF Medium",
                      fontWeight: "bold",
                    }}
                  >
                    {location.state?.repoName ?? "NA"}
                  </Text>
                </ConfigFieldContainer>
              </BuildFieldSection>
            </ConfigSection>
            {buildStatus === "Success" && isConfigureStep && (
              <ConfigSection style={{ height: "33%" }}>
                <Text
                  style={{
                    fontSize: "1.39rem",
                    marginBottom: "1.225rem",
                    display: "flex",
                    gap: "0.84rem",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    fontFamily: "Articulat CF Medium",
                    fontWeight: "bold",
                  }}
                >
                  <img
                    src={BuildConfigIcon}
                    alt="Settings Icon"
                    style={{ height: "2.225rem", width: "2.225rem" }}
                  />
                  API Server Configuration
                </Text>
                <CustomRow>
                  <Column xs={6} lg={6}>
                    <Text
                      style={{
                        fontSize: "1.112rem",
                        fontWeight: "500",
                        color: "#CCCCCC",
                        overflow: "visible",
                        whiteSpace: "normal",
                        textOverflow: "clip",
                        letterSpacing: "0",
                      }}
                    >
                      Do you want configure this API server for this custom
                      transform?
                    </Text>
                  </Column>
                  <Column xs={4.9} lg={4.9}>
                    <InputContainer>
                      <ToggleButton
                        text1={"Yes"}
                        text2={"No"}
                        value1={true}
                        value2={false}
                        toggleValue={customTransfer}
                        toggleFunction={(value) => {
                          setCustomTransfer(value);
                        }}
                        // isDisabled={customTransfer ? selectedEnvIndex <= 1 : selectedEnvIndex <= 0}
                      />
                    </InputContainer>
                  </Column>
                </CustomRow>
              </ConfigSection>
            )}
            <BuildResultSection>
              <Text
                style={{
                  marginBottom:
                    buildStatus === "Success" && isConfigureStep
                      ? "1.44rem"
                      : "2.084rem",
                  display: "flex",
                  fontSize: "1.39rem",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "0.84rem",
                  fontFamily: "Articulat CF Medium",
                  fontWeight: "bold",
                }}
              >
                <img
                  style={{ width: "2.225rem", height: "2.225rem" }}
                  src={BuildStatusIcon}
                  alt="Status Icon"
                />
                Build Result
              </Text>
              <div
                style={{
                  display: "flex",
                  gap: "1.67rem",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  maxWidth: "90%",
                }}
              >
                <img
                  src={
                    buildStatus === "In Progress"
                      ? InProgressIcon
                      : buildStatus === "Failed"
                        ? ErrorIcon
                        : SuccessIcon
                  }
                  style={{ height: "3.44rem", width: "4.44rem" }}
                  alt="Status Icon"
                />
                <Text
                  style={{
                    fontSize: "1.112rem",
                    fontStyle: "italic",
                    fontWeight: "500",
                    color: "#CCCCCC",
                    overflow: "visible",
                    whiteSpace: "normal",
                    textOverflow: "clip",
                    letterSpacing: "0",
                  }}
                >
                  {buildStatus === "In Progress"
                    ? "The build is currently in progress, please wait for the build to be completed to view result"
                    : buildStatus === "Failed"
                      ? errorResponse
                      : "The build is successful. Please declare the Environment Variables to continue"}
                </Text>
              </div>
            </BuildResultSection>
          </PageContentLeftSection>
          <PageContentRightSection
            style={{ ...(isConfigureStep ? { padding: 0 } : {}) }}
          >
            {!isConfigureStep && (
              <Text
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "flex-start",
                  gap: "0.84rem",
                  fontSize: "1.39rem",
                  marginBottom: "2.084rem",
                  fontFamily: "Articulat CF Medium",
                  fontWeight: "bold",
                }}
              >
                <img src={BuildLogsIcon} alt="Logs Icon" />
                Logs
              </Text>
            )}
            {!isConfigureStep && (
              <ReactJson
                src={logs.length ? logs : [{ status: "Loading Build logs..." }]}
                theme={"twilight"}
                indentWidth={3}
                groupArraysAfterLength={1000}
                displayObjectSize={false}
                displayDataTypes={false}
                quotesOnKeys={false}
                displayArrayKey={false}
                style={{
                  backgroundColor: "#2F2F2F",
                  borderRadius: "0.7rem",
                  fontFamily: "'Fira Code', Consolas, 'Courier New', monospace",
                }}
              />
            )}
            {isConfigureStep && (
              <>
                {envConfigs.length <= 0 && (
                  <>
                    <CustomRow
                      style={{
                        paddingLeft: "1.112rem",
                        paddingRight: "1.112rem",
                        paddingTop: "1.112rem",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: "1.39rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          gap: "0.84rem",
                        }}
                      ></Text>
                      <img
                        src={PlusIcon}
                        alt="Plus Icon"
                        style={{
                          height: "1.39rem",
                          width: "1.39rem",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setEnvConfigs((prev) => [
                            ...prev,
                            {
                              name: "",
                              label: "",
                              type: { name: "Text", value: "text" },
                              placeholder: "",
                              description: "",
                              isRequired: true,
                              direction: "input",
                            },
                          ]);
                          setSelectedEnvIndex(envConfigs.length);
                          if (selectedEnvIndex === -1) setSelectedEnvIndex(0);
                        }}
                      />
                    </CustomRow>
                    <CustomRow>
                      <Column
                        xs={12}
                        style={{
                          height: "100%",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: "2.3rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            color: "#F6F6F6",
                            paddingLeft: "2rem",
                            gap: "0.84rem",
                            fontWeight: "580",
                          }}
                        >
                          Configure Deployment
                        </Text>

                        <Text
                          style={{
                            fontSize: "1.250rem",
                            fontWeight: "500",
                            color: "#B0B0B0",
                            overflow: "visible",
                            whiteSpace: "normal",
                            textOverflow: "clip",
                            letterSpacing: "0",
                            paddingRight: "2rem",
                            paddingLeft: "2rem",
                            marginTop: "0.6rem",
                          }}
                        >
                          You have no Variables configured at the moment. Click
                          on the{" "}
                          <img
                            src={PlusIcon}
                            alt="Plus Icon"
                            style={{
                              height: "1.39rem",
                              width: "1.39rem",
                              cursor: "pointer",
                            }}
                          />{" "}
                          above to add a new configuration
                        </Text>

                        <Text
                          style={{
                            fontSize: "1rem",
                            fontWeight: "100",
                            color: "#47CCD6",
                            overflow: "visible",
                            whiteSpace: "normal",
                            textOverflow: "clip",
                            letterSpacing: "0",
                            paddingRight: "2rem",
                            paddingLeft: "2rem",
                            marginTop: "0.6rem",
                          }}
                        >
                          <InfoIconStyled />
                          Not adding any configurations will save empty
                          configurations with your application
                        </Text>

                        <EmptyStateContainer></EmptyStateContainer>
                      </Column>
                    </CustomRow>
                  </>
                )}

                {envConfigs.length > 0 && (
                  <>
                    <CustomRow
                      style={{
                        padding: "1.112rem",
                        borderBottom: "0.069rem solid #4B4B4B",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: "1.39rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          gap: "0.84rem",
                        }}
                      >
                        <img src={ConfigureEnvIcon} alt="Configure" />
                        Configure Environmental Variable
                      </Text>
                      <img
                        src={PlusIcon}
                        alt="Plus Icon"
                        style={{
                          height: "1.39rem",
                          width: "1.39rem",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setEnvConfigs((prev) => [
                            ...prev,
                            {
                              name: "",
                              label: "",
                              type: { name: "Text", value: "text" },
                              placeholder: "",
                              description: "",
                              isRequired: true,
                              direction: "input",
                            },
                          ]);
                          setSelectedEnvIndex(envConfigs.length);
                          if (selectedEnvIndex === -1) setSelectedEnvIndex(0);
                        }}
                      />
                    </CustomRow>
                    <CustomRow style={{ height: "calc(100% - 4.583rem)" }}>
                      <Column
                        xs={4}
                        style={{
                          height: "100%",
                          backgroundColor: "#2F2F2F",
                          overflow: "auto",
                          borderRight: "0.069rem solid #4B4B4B",
                        }}
                      >
                        <CustomRow
                          style={{
                            justifyContent: "flex-start",
                            gap: "0.55rem",
                            padding: "0.84rem",
                          }}
                        >
                          {envConfigs.map((env, index) => (
                            <Column
                              style={{
                                backgroundColor: "#2F2F2F",
                                borderRadius: "0.55rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: "0.486rem",
                                border: "0.069rem solid #2F2F2F",
                                cursor: "pointer",
                                ...(index === selectedEnvIndex
                                  ? {
                                      border: "0.069rem solid #4B4B4B",
                                      backgroundColor: "#4B4B4B",
                                    }
                                  : {}),
                                ...(index === envConfigs.length - 1 &&
                                index === selectedEnvIndex
                                  ? {
                                      // border: "2px solid #47CCD6",
                                      backgroundColor: "#4B4B4B",
                                    }
                                  : {}),
                              }}
                              onClick={() => setSelectedEnvIndex(index)}
                            >
                              <Text style={{ fontSize: "1.112rem" }}>
                                {env.label || `Configure Name ${index + 1}`}
                              </Text>
                              <img
                                src={ChevronRight}
                                alt="right"
                                style={{ height: "1.67rem", width: "1.67rem" }}
                              />
                            </Column>
                          ))}
                        </CustomRow>
                      </Column>
                      <Column
                        xs={8}
                        style={{
                          height: "100%",
                          backgroundColor: "#2F2F2F",
                          overflow: "auto",
                        }}
                      >
                        {selectedEnvIndex !== -1 ? (
                          <CustomRow
                            style={{
                              padding: "1.112rem",
                              gap: "1.39rem",
                            }}
                          >
                            <Column
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Text style={{ fontSize: "1.39rem" }}>
                                Variable Details
                              </Text>
                              <Text
                                style={{
                                  fontSize: "0.972rem",
                                  textDecoration: "underline",
                                  cursor: customTransfer
                                    ? selectedEnvIndex > 1
                                      ? "pointer"
                                      : "not-allowed"
                                    : selectedEnvIndex > 0
                                      ? "pointer"
                                      : "not-allowed",
                                }}
                                onClick={() => {
                                  if (customTransfer) {
                                    if (selectedEnvIndex > 1) {
                                      toggleDeleteModal();
                                    }
                                  } else {
                                    if (selectedEnvIndex > 0) {
                                      toggleDeleteModal();
                                    }
                                  }
                                }}
                              >
                                Delete
                              </Text>
                            </Column>
                            <Column xs={12} lg={5.7}>
                              <InputContainer>
                                <Label>Environment Variable Name *</Label>
                                <InputFieldContainer>
                                  <InputField
                                    name="name"
                                    value={envConfigs[selectedEnvIndex].name}
                                    onChange={(e) =>
                                      e.target.value.length < 31 &&
                                      setEnvConfigs((prev) => {
                                        let temp = [...prev];
                                        temp[selectedEnvIndex].name =
                                          e.target.value;
                                        return temp;
                                      })
                                    }
                                    readOnly={
                                      customTransfer
                                        ? selectedEnvIndex <= 1
                                        : selectedEnvIndex <= 0
                                    }
                                  />
                                  <Text
                                    style={{
                                      fontSize: "0.972rem",
                                      lineHeight: "1.112rem",
                                      color: "#B0B0B0",
                                      overflow: "visible",
                                      whiteSpace: "normal",
                                      textOverflow: "clip",
                                    }}
                                  >
                                    {envConfigs[selectedEnvIndex].name?.length >
                                    0
                                      ? `${envConfigs[selectedEnvIndex].name?.length}/30`
                                      : `0/30`}
                                  </Text>
                                </InputFieldContainer>
                              </InputContainer>
                            </Column>
                            <Column xs={12} lg={5.7}>
                              <InputContainer>
                                <Label>Required</Label>
                                <ToggleButton
                                  text1={"Yes"}
                                  text2={"No"}
                                  value1={true}
                                  value2={false}
                                  toggleValue={
                                    envConfigs[selectedEnvIndex].isRequired
                                  }
                                  toggleFunction={(value) => {
                                    setEnvConfigs((prev) => {
                                      let temp = [...prev];
                                      temp[selectedEnvIndex].isRequired = value;
                                      return temp;
                                    });
                                  }}
                                  isDisabled={
                                    customTransfer
                                      ? selectedEnvIndex <= 1
                                      : selectedEnvIndex <= 0
                                  }
                                />
                              </InputContainer>
                            </Column>
                            <Column>
                              <InputContainer>
                                <Label>Configuration Name *</Label>
                                <InputFieldContainer>
                                  <InputField
                                    name="name"
                                    value={envConfigs[selectedEnvIndex].label}
                                    onChange={(e) =>
                                      setEnvConfigs((prev) => {
                                        let temp = [...prev];
                                        temp[selectedEnvIndex].label =
                                          e.target.value;
                                        return temp;
                                      })
                                    }
                                    readOnly={
                                      customTransfer
                                        ? selectedEnvIndex <= 1
                                        : selectedEnvIndex <= 0
                                    }
                                  />
                                </InputFieldContainer>
                              </InputContainer>
                            </Column>
                            <Column xs={12} lg={5.7}>
                              <InputContainer>
                                <Label>Type *</Label>
                                <GenericSelection
                                  dropdownItems={[
                                    { name: "Text", value: "text" },
                                    { name: "Number", value: "number" },
                                    { name: "Password", value: "password" },
                                    { name: "Topic", value: "topic" },
                                  ]}
                                  selectedItem={
                                    envConfigs[selectedEnvIndex]?.type || {
                                      name: "Text",
                                      value: "text",
                                    } // Default to "Text"
                                  }
                                  placeholder={"Select a Type"}
                                  onSelect={(item) => {
                                    setEnvConfigs((prev) => {
                                      let temp = [...prev];
                                      temp[selectedEnvIndex].type = item;
                                      return temp;
                                    });
                                  }}
                                  nameFunction={(item) => item.name}
                                  filterFunction={(arr, text) =>
                                    arr.filter((item) =>
                                      item.name
                                        .toLowerCase()
                                        .includes(text.toLowerCase()),
                                    )
                                  }
                                  searchPlaceHolder={"Search for Types"}
                                  noMargin={true}
                                  disable={
                                    customTransfer
                                      ? selectedEnvIndex <= 1
                                      : selectedEnvIndex <= 0
                                  }
                                />
                              </InputContainer>
                            </Column>
                            <Column xs={12} lg={5.7}>
                              <InputContainer>
                                <Label>Topic Type</Label>
                                <ToggleButton
                                  text1={"Input"}
                                  text2={"Output"}
                                  value1={"input"}
                                  value2={"output"}
                                  toggleValue={
                                    envConfigs[selectedEnvIndex].direction
                                  }
                                  toggleFunction={(value) => {
                                    setEnvConfigs((prev) => {
                                      let temp = [...prev];
                                      temp[selectedEnvIndex].direction = value;
                                      return temp;
                                    });
                                  }}
                                  isDisabled={
                                    envConfigs[selectedEnvIndex].type?.value !==
                                    "topic"
                                  }
                                />
                              </InputContainer>
                            </Column>
                            <Column xs={12}>
                              <InputContainer>
                                <Label>Placeholder *</Label>
                                <InputFieldContainer>
                                  <InputField
                                    name="placeholder"
                                    value={
                                      envConfigs[selectedEnvIndex].placeholder
                                    }
                                    onChange={(e) =>
                                      setEnvConfigs((prev) => {
                                        let temp = [...prev];
                                        temp[selectedEnvIndex].placeholder =
                                          e.target.value;
                                        return temp;
                                      })
                                    }
                                    readOnly={
                                      customTransfer
                                        ? selectedEnvIndex <= 1
                                        : selectedEnvIndex <= 0
                                    }
                                  />
                                </InputFieldContainer>
                              </InputContainer>
                            </Column>
                            <Column xs={12}>
                              <InputContainer>
                                <Label>Description</Label>
                                <InputFieldContainer>
                                  <InputField
                                    name="placeholder"
                                    value={
                                      envConfigs[selectedEnvIndex].description
                                    }
                                    onChange={(e) => {
                                      setEnvConfigs((prev) => {
                                        let temp = [...prev];
                                        temp[selectedEnvIndex].description =
                                          e.target.value;
                                        return temp;
                                      });
                                    }}
                                    readOnly={
                                      customTransfer
                                        ? selectedEnvIndex <= 1
                                        : selectedEnvIndex <= 0
                                    }
                                  />
                                </InputFieldContainer>
                              </InputContainer>
                            </Column>
                          </CustomRow>
                        ) : (
                          <CustomRow
                            style={{ padding: "1.112rem", gap: "1.39rem" }}
                          >
                            <Column>
                              <Text
                                style={{
                                  overflow: "visible",
                                  whiteSpace: "normal",
                                  textOverflow: "clip",
                                }}
                              >
                                Click on the '{" "}
                                <img src={PlusIcon} alt="Plus Icon" /> ' above
                                to add a new configuration
                              </Text>
                            </Column>
                            <Column>
                              <Text
                                style={{
                                  overflow: "visible",
                                  whiteSpace: "normal",
                                  textOverflow: "clip",
                                }}
                              >
                                Not adding any configurations will save empty
                                configurations with your application
                              </Text>
                            </Column>
                          </CustomRow>
                        )}
                      </Column>
                    </CustomRow>
                  </>
                )}
              </>
            )}
          </PageContentRightSection>
        </PageContentSection>
        <BottomButtonSection>
          <StyledButtonsecondary
            disabled={buildStatus === "In Progress" || configSaveLoading}
            onClick={() =>
              history.push("/codeEditor", {
                isRedirected: true,
                applicationId: location.state?.applicationId,
                gitUsername: location.state?.username,
                repositoryName: location.state?.repoName,
                imageName: location.state?.imageName,
                tag: location.state?.tagName,
                applicationName: location.state?.oldApplicationName,
                applicationVersion: location.state?.oldApplicationVersion,
                description: location.state?.oldDescription,
                icon_file: location.state?.oldIconFile,
                userConfigs: location.state?.oldUserConfigs,
                authProvider: location.state?.authProvider,
              })
            }
            style={{ width: "14.167rem" }}
          >
            Back
          </StyledButtonsecondary>
          <StyledButtonPrimary
            style={{
              // backgroundColor: buildStatus === "Failed" ? "#EA3A3A" : "#47CCD6",
              border: "none",
              width: "14.167rem",
            }}
            disabled={buildStatus === "In Progress" || configSaveLoading}
            onClick={() => {
              if (buildStatus === "Failed") {
                history.push("/codeEditor", {
                  isRedirected: true,
                  applicationId: location.state?.applicationId,
                  gitUsername: location.state?.username,
                  repositoryName: location.state?.repoName,
                  imageName: location.state?.imageName,
                  tag: location.state?.tagName,
                  applicationName: location.state?.oldApplicationName,
                  applicationVersion: location.state?.oldApplicationVersion,
                  description: location.state?.oldDescription,
                  icon_file: location.state?.oldIconFile,
                  userConfigs: location.state?.oldUserConfigs,
                  authProvider: location.state?.authProvider,
                });
              } else {
                if (!isConfigureStep) {
                  setConfigureStep(true);
                } else {
                  handleConfigSave();
                }
              }
            }}
          >
            {buildStatus === "Failed"
              ? "Try Again"
              : isConfigureStep
                ? "Save"
                : "Configure ENVs"}
          </StyledButtonPrimary>
        </BottomButtonSection>
        {showDeleteModal && (
          <GenericModal
            show={showDeleteModal}
            onClose={toggleDeleteModal}
            title={`Delete ENV?`}
          >
            <CustomRow>
              <Column xs={12}>
                <ModalDescription>
                  Are you sure you want to delete this ENV? This cannot be
                  undone. You'd have to create the ENV again to start using it.
                </ModalDescription>
              </Column>
            </CustomRow>
            <CustomRow
              style={{
                display: "flex",
                gap: "1.112rem",
                justifyContent: "flex-end",
                marginTop: "6.1805rem",
              }}
            >
              <StyledButtonsecondary
                onClick={toggleDeleteModal}
                style={{ width: "14.167rem" }}
              >
                Cancel
              </StyledButtonsecondary>
              <StyledDeleteButton
                style={{ width: "14.167rem" }}
                onClick={handleDelete}
              >
                Delete
              </StyledDeleteButton>
            </CustomRow>
          </GenericModal>
        )}
        {isPublishModalOpen && (
          <PublishConnectorModal
            isModelOpen={isPublishModalOpen}
            setIsModalOpen={setPublishModalOpen}
            buildId={buildId}
            applicationId={location.state?.applicationId}
            customConnectorIconFile={location.state?.oldIconFile}
            customApplicationName={location.state?.oldApplicationName || null}
            customApplicationVersion={
              location.state?.oldApplicationVersion || null
            }
            customApplicationDescription={
              location.state?.oldDescription || null
            }
          />
        )}
      </MainContainer>
      <GenericModal
        show={isConfigSaveErrorModalOpen}
        title={"Your ENVs couldn't be configured."}
      >
        <CustomRow>
          <Column>
            <Text
              style={{
                marginBottom: "5.55rem",
                overflow: "visible",
                whiteSpace: "normal",
                textOverflow: "clip",
                opacity: "0.7",
                color: "#ECECEC",
                fontSize: "1.25rem",
              }}
            >
              {configSaveErrorMessage}
            </Text>
          </Column>
          <Column
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "1.39rem",
              alignItems: "center",
            }}
          >
            <StyledButtonsecondary
              onClick={() => setConfigSaveErrorModalOpen(false)}
              style={{ width: "14.167" }}
              width="14.167rem"
            >
              Cancel
            </StyledButtonsecondary>
            <StyledButtonPrimary
              style={{
                width: "14.167rem",
                border: "none",
              }}
              onClick={() => {
                setConfigSaveErrorModalOpen(false);
                handleConfigSave();
              }}
              disabled={configSaveLoading}
            >
              Retry
            </StyledButtonPrimary>
          </Column>
        </CustomRow>
      </GenericModal>
    </>
  );
}
