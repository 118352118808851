import React, { createContext, useState, useCallback, useMemo } from "react";
import decode from "jwt-decode";
import axiosAdapter, { decryptJwe } from "../utils";
import { env } from "../env";
import PropTypes from "prop-types";

// Create AuthContext
export const AuthContext = createContext({
  userRole: "",
  username: "",
  userEmail: "",
  userFullName: "",
  isTokenPresent: false,
  isTokenBeingDecrypted: true,
  loginTime: null,
  expiryTime: null,
  selectedWorkspaceData: null,
  setSelectedWorkspaceData: () => {},
  login: () => {},
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const [username, setUsername] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userFullName, setUserFullName] = useState("");
  const [loginTime, setLoginTime] = useState(null);
  const [expiryTime, setExpiryTime] = useState(null);
  const [isTokenPresent, setIsTokenPresent] = useState(false);
  const [isTokenBeingDecrypted, setIsTokenBeingDecrypted] = useState(true);
  const [selectedWorkspaceData, setSelectedWorkspaceDataState] = useState(null);

  const setSelectedWorkspaceData = useCallback((selectedWorkspaceData) => {
    if (selectedWorkspaceData)
      localStorage.setItem("selectedWorkspaceId", selectedWorkspaceData.id);
    else localStorage.removeItem("selectedWorkspaceId");
    setSelectedWorkspaceDataState(selectedWorkspaceData);
  }, []);

  // Memoized login function
  const login = useCallback((jweToken) => {
    try {
      decryptJwe(jweToken).then((jwtToken) => {
        setIsTokenBeingDecrypted(false);
        const { userRole, username, fullName, emailId, iat, exp } =
          decode(jwtToken);
        setUsername(username);
        setUserRole(userRole);
        setUserEmail(emailId);
        setUserFullName(fullName);
        setLoginTime(iat);
        setExpiryTime(exp);
        setIsTokenPresent(true);
        localStorage.setItem("token", jweToken);
      });
    } catch (error) {
      console.error("Failed to decode token during login", error);
    }
  }, []);

  // Memoized logout function
  const logout = useCallback(() => {
    axiosAdapter(
      "POST",
      env.REACT_APP_URL + "user-management/userLogout",
    ).catch(() => {});
    localStorage.removeItem("token");
    setUsername("");
    setUserRole("");
    setUserEmail("");
    setUserFullName("");
    setLoginTime(null);
    setExpiryTime(null);
    setIsTokenPresent(false);
    setSelectedWorkspaceData(null);
  }, [setSelectedWorkspaceData]);

  if (!isTokenPresent) {
    const jweToken = localStorage.getItem("token");
    if (jweToken) {
      try {
        decryptJwe(jweToken).then((jwtToken) => {
          setIsTokenBeingDecrypted(false);
          const { userRole, username, fullName, emailId, iat, exp } =
            decode(jwtToken);
          setUsername(username);
          setUserRole(userRole);
          setUserEmail(emailId);
          setUserFullName(fullName);
          setLoginTime(iat);
          setExpiryTime(exp);
          setIsTokenPresent(true);
        });
      } catch (error) {
        console.error("Invalid token", error);
        logout();
      }
    }
  }

  // Memoize the context value to prevent unnecessary re-renders
  const authValue = useMemo(
    () => ({
      userRole,
      username,
      userEmail,
      userFullName,
      isTokenPresent,
      isTokenBeingDecrypted,
      loginTime,
      expiryTime,
      selectedWorkspaceData,
      setSelectedWorkspaceData,
      login,
      logout,
    }),
    [
      userRole,
      username,
      userEmail,
      userFullName,
      isTokenPresent,
      isTokenBeingDecrypted,
      loginTime,
      expiryTime,
      selectedWorkspaceData,
      setSelectedWorkspaceData,
      login,
      logout,
    ],
  );

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
